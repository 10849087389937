import request from '@/utils/request'
const userApi = {
  add: '/goods/create',
  del: '/goods/delete',
  update: '/goods/update',
  editStatus: '/goods/update-status',
  detail: '/goods/get',
  list: '/goods/page',
  // detailByMId: '/goodscate/get-channel', 
  // listgCate: '/goodscate/page',
}

// 新增商品
export function add (data) {
  return request({
    url: userApi.add,
    method: 'post',
    data: data
  })
}
// 删除商品
export function del (id) {
  return request({
    url: userApi.del + id,
    method: 'delete'
  })
}
// 查询商品列表
export function list (query) {
  return request({
    url: userApi.list,
    method: 'get',
    params: query
  })
}
// 修改商品
export function update (data) {
  return request({
    url: userApi.update,
    method: 'put',
    data: data
  })
}
// 改 状态 开启和关闭  微信
export function editStatus (data) {
  return request({
    url: userApi.editStatus,
    method: 'post',
    data: data
  })
}
